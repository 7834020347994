import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { useVocabularyData } from 'app/utils/vocabulary'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export interface Props {
  languageCode: string
  languagePrefix: string
  title?: string
  images?: ImageProps[]
  claim?: string
  size?: string
  guests?: string
  room_view?: string
  slug?: string
  index?: number
}

export const RoomItem = memo(function RoomItem({
  languageCode,
  languagePrefix,
  title,
  images,
  claim,
  size,
  guests,
  room_view,
  slug,
  index,
}: Props) {
  const href =
    languagePrefix != 'it' ? '/' + languagePrefix + '/' + slug : '/' + slug
  return (
    <Container>
      <FadeInUp delay={index && index % 2 == 1 ? 0.15 : 0}>
        <Link to={href}>
          <Wrapper>
            <Header>
              {title ? (
                <Title dangerouslySetInnerHTML={{ __html: title }}></Title>
              ) : null}
              <RoomInfos>
                {size ? (
                  <RoomInfo
                    dangerouslySetInnerHTML={{ __html: size }}
                  ></RoomInfo>
                ) : null}
                {guests ? (
                  <RoomInfo
                    dangerouslySetInnerHTML={{ __html: guests }}
                  ></RoomInfo>
                ) : null}
                {room_view ? (
                  <RoomInfo
                    dangerouslySetInnerHTML={{ __html: room_view }}
                  ></RoomInfo>
                ) : null}
              </RoomInfos>
            </Header>

            <ImageContainer>
              <DecoHover className="deco-hover" />
              <ImageWrapper>
                {images ? <StyledImage {...images[0]} /> : null}
              </ImageWrapper>
            </ImageContainer>

            {claim ? (
              <Claim
                dangerouslySetInnerHTML={{
                  __html: claim.replace(/<br\s*\/?>/g, ' '),
                }}
              ></Claim>
            ) : null}
            <StyledButton
              label={useVocabularyData('details', languageCode)}
              URL={href}
              variant="outline"
            ></StyledButton>
          </Wrapper>
        </Link>
      </FadeInUp>
    </Container>
  )
})

const Container = styled.div`
  position: relative;
  width: 50%;
  padding: 0 3vw;
  margin: 3vw 0;
  text-align: center;

  @media (max-width: 991px) {
    width: 100%;
    padding: 0;
    margin: 60px 0;
  }

  &:hover {
    header {
      transform: translateY(-1.5vw);
    }
    img {
      transform: scale(1.1);
    }
    .deco-hover {
      height: 4vw;
    }
  }
`

const Wrapper = styled.article``

const Header = styled.header`
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
`

const Title = styled.h3`
  font-weight: 400;
  font-size: 2vw;
  line-height: 2vw;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  display: block;
  text-transform: uppercase;

  @media (max-width: 991px) {
    font-size: 28px;
    line-height: 1;
  }
`

const RoomInfos = styled.ul`
  margin: 1vw 0;
  @media (max-width: 991px) {
    margin: 10px 0;
  }
`
const RoomInfo = styled.li`
  display: inline-block;
  font-size: 0.9vw;
  letter-spacing: 2px;
  margin: 0 20px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.subheading};
  position: relative;

  &::before {
    content: ' ';
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: -20px;
    transform: translate3d(0, -50%, 0);
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
  }

  &:first-child {
    &:before {
      display: none;
    }
  }

  @media (max-width: 1439px) {
    font-size: 12px;
    margin: 0 10px;

    &::before {
      width: 4px;
      height: 4px;
      left: -10px;
    }
  }
`
const ImageContainer = styled.div`
  position: relative;
`
const ImageWrapper = styled.div`
  overflow: hidden;
  margin-top: 1.8vw;
  position: relative;
`
const DecoHover = styled.div`
  width: 1px;
  height: 0;
  position: absolute;
  left: 50%;
  top: 0;
  z-index: 2;
  background: ${({ theme }) => theme.colors.variants.primaryLight};
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  transform: translate3d(-50%, -50%, 0);
`
const StyledImage = styled(Image)`
  display: block;

  img {
    width: 100%;
    height: auto;
    transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  @media (max-width: 991px) {
    margin-top: 15px;
  }
`

const Claim = styled.p`
  margin-top: 20px;

  @media (max-width: 991px) {
    margin-top: 10px;
  }
`

const StyledButton = styled(Button)`
  margin: 20px auto;
`
