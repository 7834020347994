import styled from '@emotion/styled'
import React, { memo } from 'react'

import { RoomItem } from './item'

export interface Props {
  rooms?: any[]
}

export const RoomsList = memo(function RoomsList({ rooms }: Props) {
  return (
    <Container>
      <ElemsWrapper>
        {rooms
          ? rooms.map((elem, index) =>
              (index + 1) % 4 == 0 ? (
                <Deco key={index} data-index={index} />
              ) : null,
            )
          : null}

        {rooms
          ? rooms.map((elem, index) => (
              <React.Fragment key={index}>
                <RoomItem {...elem} index={index} />
              </React.Fragment>
            ))
          : null}
      </ElemsWrapper>
    </Container>
  )
})

const Container = styled.section`
  position: relative;
  width: 100%;
  margin: 7vw 0;

  @media (max-width: 991px) {
    margin: 0;
  }
`

const ElemsWrapper = styled.div`
  padding: 0 7vw;
  display: flex;
  flex-wrap: wrap;
  position: relative;

  @media (max-width: 991px) {
    display: block;
    padding: 0 30px;
  }
`

const Title = styled.h2`
  font-weight: 400;
  font-size: 3vw;
  margin: 5vw 0;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  display: block;
  text-transform: uppercase;
  position: relative;
  padding: 0 10vw;

  @media (max-width: 991px) {
    font-size: 28px;
    margin: 30px 0;
  }
`

const Deco = styled.div`
  left: 0;
  width: 100%;
  height: calc(45vw + 50px);
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  position: absolute;
  display: none;

  &[data-index='3'] {
    display: block;
    top: calc(20vw + 0px);
  }
  &[data-index='7'] {
    top: calc(100vw + 200px);
    display: block;
  }

  @media (max-width: 991px) {
    display: none !important;
  }
`
